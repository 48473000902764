import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery, { Photo } from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import AndrewOnFire from '../../images/grid/andrew-on-fire.jpg';
import AddisonGrooveProjectBonnaroo from '../../images/grid/addison-groove-project-bonnaroo.jpg'
import AtTheBarn from '../../images/grid/at-the-barn.jpg';
import HornSection from '../../images/grid/horn-section.jpg';
import MagicCityBean from '../../images/grid/magic-city-bean.jpg';
import PianoLangdonStreet from '../../images/grid/piano-langdon-street.jpg';
import PicklesAnaisAndrewAsFates from '../../images/grid/pickles-anais-andrew-as-fates.jpg';
import RayVegaQuartetHotelVT from '../../images/grid/ray-vega-quartet-hotel-vt.jpg';
import RobGabeAndrewTrioYoshi from '../../images/grid/trio-rob-gabe-andrew-yoshi.jpg';
import TromboneHadestown from '../../images/grid/trombone-hadestown.jpg';
import TromboneHadestown2 from '../../images/grid/trombone-hadestown-2.jpg';
import TromboneLangdonStreet from '../../images/grid/trombone-langdon-street.jpg';

// import { ReactReduxContext } from "react-redux";

const photos = [
    {
        src: MagicCityBean,
        width: 4,
        height: 3,
        title: 'With Magic City at the Radio Bean in Burlington, VT'
    },
    {
        src: AddisonGrooveProjectBonnaroo,
        width: 4,
        height: 3,
        title: 'With Addison Groove Project at Bonnaroo 2004'
    },
    {
        src: AtTheBarn,
        width: 4,
        height: 3,
        title: 'Rehearsing with the Trey Anastasio Band at the barn'
    },
    {
        src: HornSection,
        width: 4,
        height: 3,
        title: 'With the Trey Anastasio Band'
    },
    {
        src: PianoLangdonStreet,
        width: 3,
        height: 4,
        title: 'At the Langdon Street Cafe in Montpelier, VT',
        credit: 'Dave Jablonski'
    },
    {
        src: PicklesAnaisAndrewAsFates,
        width: 3,
        height: 2,
        title: 'Standing in as a Fate with Adam Moss and Anais Mitchell'
    },
    {
        src: RayVegaQuartetHotelVT,
        width: 4,
        height: 3,
        title: 'With Ray Vega at Hotel Vermont in Burlington, VT'
    },
    {
        src: RobGabeAndrewTrioYoshi,
        width: 1,
        height: 1,
        title: 'Trio with Rob Morse and Gabe Jarrett at Sushi Yoshi in Stowe, VT'
    },
    {
        src: TromboneHadestown,
        width: 2,
        height: 3,
        title: 'With Anais Mitchell and the Hadestown Orchestra',
        credit: 'Jay Sansone'
    },
    {
        src: TromboneHadestown2,
        width: 4,
        height: 3,
        title: 'With Anais Mitchell and the Hadestown Orchestra'
    },
    {
        src: TromboneLangdonStreet,
        width: 4,
        height: 3,
        title: 'At the Langdon Street Cafe in Montpelier, VT',
        credit: 'Dave Jablonski'
    },
    {
        src: AndrewOnFire,
        width: 4,
        height: 3,
        title: 'Je suis en feu',
        credit: 'Dave Jablonski'
    },
];

// NEW

class PhotoGallery extends React.Component {
    state = { modalIsOpen: false, currentIndex: 0 };

    openModal = (event, { photo, index }) => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen, currentIndex: index }));
    };

    closeModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen, currentIndex: 0 }));
    };

    onImageChange = (index) => {
        console.log(index)
        this.setState(state => ({ currentIndex: index }));
    };

    render() {
        const { modalIsOpen } = this.state;        

        const CustomModalFooter = ({ currentIndex, views }) => {
            const activeView = currentIndex + 1;
            const totalViews = views.length;

            const captionFontSize = window.innerWidth > 992 ? '20px' : '16px'
            const photoCreditFontSize = window.innerWidth > 992 ? '14px' : '12px'

            if (!activeView || !totalViews) return null;
            return (
                <>
                    <div style={{ textAlign: 'center', margin: 'auto' }}>
                        <p className="customText" style={{ fontSize: captionFontSize, textAlign: 'center' }}>
                            {photos[currentIndex].title}
                        </p>
                        {photos[currentIndex].credit == undefined ?
                            <p>&nbsp;</p>
                            :
                            <p className="customText" style={{ fontSize: photoCreditFontSize, textAlign: 'center' }}>
                                Photo credit: {photos[currentIndex].credit}
                            </p>
                        }

                    </div>
                    <span class="react-images__footer__count css-w6xjhe css-1ycyyax">
                        {activeView} / {totalViews}
                    </span>
                </>
            );
        };

        return (
            <>
                {/* <button
                    type="button"
                    className="btn-fullScreen"
                    onClick={this.toggleModal}
                >
                    Open Modal
                </button> */}
                <Gallery photos={photos} onClick={this.openModal} />

                <ModalGateway>
                    {modalIsOpen ? (
                        <Modal onClose={this.closeModal}>
                            <Carousel
                                currentIndex={this.state.currentIndex}
                                components={{ FooterCount: CustomModalFooter }}
                                views={photos}
                            // views={photos.map(x => ({
                            //     ...x,
                            //     srcset: x.srcSet,
                            //     caption: x.title,
                            //     title: x.title
                            // }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>

            </>
        );
    }
}

export default PhotoGallery;
