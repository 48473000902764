import React, { Component } from "react";
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';
import MediaBox from './MediaBox';

const GabeAnthonyAndrewHotelVermont = 'https://drive.google.com/uc?export=download&id=1Fke5NhrAle_77XoDcrQ0VwlhB_tWvqsy';
const HadestownGPN2011 = 'https://drive.google.com/uc?export=download&id=1ZXhfQaticlzofeMrCImwRWsxypWwwZtM';
const StaceyBryanJamesAnthonyAndrewHalvorsons = 'https://drive.google.com/uc?export=download&id=1sc1J4A573MujdQ52cd4COygccOqiBQbi';
const TreyBandBonnaroo2002Mozambique = 'https://drive.google.com/uc?export=download&id=1YbKCd2iDKezlHDGbTkqEingh-gAjLARq';


class VideoPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {
        return (

            <div>
                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />

                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}>
                                Video
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is footage of the Trey Anastasio Band performing at the Bonnaroo Music Festival in Manchester, TN on June 23, 2002.
                            </p>

                            <MediaBox source={TreyBandBonnaroo2002Mozambique} title="Mozambique" creditsTextIsCentered={true} composer="Trey Anastasio" credits="Trey Anastasio (guitar), Russ Lawton (drums), Tony Markellis (bass), Ray Paczkowski (keyboards), Cyro Baptista (percussion), Peter Apfelbaum (tenor sax), Dave Grippo (alto sax), Russell Remington (tenor sax), Jennifer Hartswick (trumpet), Andrew Moroz (trombone)" type="video" />
                        </div>

                        <div className="col-lg-6">
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is video of Anais Mitchell and the Hadestown Orchestra performing at the Grand Point North Music Festival in Burlington, VT on Sunday, August 14, 2011.
                            </p>
                            <MediaBox source={HadestownGPN2011} title="Our Lady of the Underground" creditsTextIsCentered={true} composer="Anais Mitchell" credits="Anais Mitchell (guitar, vocals), Michael Chorney (guitar), Robinson Morse (bass), Geza Carr (drums), Polly Vanderputten (cello), Adam Moss (viola), Andrew Moroz (trombone)" type="video" />
                        </div>

                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-lg-6">
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is a quintet show at the Discover Jazz Festival in Burlington, VT on June 5, 2017.
                            </p>
                            <MediaBox source={StaceyBryanJamesAnthonyAndrewHalvorsons} title="Invitation" creditsTextIsCentered={true} composer="Bronislaw Kaper" credits="Stacey Dillard (tenor sax), Bryan McNamara (alto sax), James Harvey (drums), Anthony Santor (bass), Andrew Moroz (piano)" type="video" />
                        </div>

                        <div className="col-lg-6">
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is a trio performance at Hotel Vermont in Burlington, VT on January 17, 2014.
                            </p>
                            <MediaBox source={GabeAnthonyAndrewHotelVermont} title="Nothing Personal" creditsTextIsCentered={true} composer="Michael Brecker" credits="Gabe Jarrett (drums), Anthony Santor (bass), Andrew Moroz (piano)" type="video" />
                        </div>

                    </div>

                </div>

                <Footer />
            </div>
        );
    }
}

export default VideoPage;
