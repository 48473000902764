import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Nav, Navbar, NavbarToggler, NavItem, NavLink, Collapse, Container } from 'reactstrap';
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';

import AndrewPiano from '../../images/andrew-piano-1.jpg';
import AndrewTrombone from '../../images/Trombone-Bean.jpg';


class MusicHomePage extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      navbarIsOpen: false
    }
  }

  toggle() {
    this.setState({
      navbarIsOpen: !this.state.navbarIsOpen
    })
  }

  render() {    

    const containerClass = window.innerWidth > 1000 ? "" : ""

    return (

      <div className={containerClass}>
      
        <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
        <NavigationBar />

        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <img class="img-fluid d-block rounded" src={AndrewTrombone} alt="" />
          </div>
          <div className="col-lg-3"></div>
        </div>
        
        <div class="row" style={{height: '5vh'}}></div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <p style={{font: 'Avenir', fontWeight: '250'}}>
            Andrew Moroz has toured and recorded with Trey Anastasio, Phish, Anais Mitchell, and Jennifer Hartswick, sharing the stage with Carlos Santana, Dave Matthews, Ani DiFranco, John Medeski, Randy Brecker, Peter Apfelbaum, and other luminaries along the way. He has served as adjunct faculty at the University of Vermont and Johnson State College.
            </p>
            <hr />
            <p style={{font: 'Avenir', fontWeight: '250'}}><i>"Andy has such an amazing ear... he could play anything you put in front of him, and play it all night, as fast as you like."</i>&nbsp;&nbsp;&nbsp;- <strong>Trey Anastasio</strong></p>
            <p style={{font: 'Avenir', fontWeight: '250'}}><i>"One of the most brilliant arrangers and composers that I've ever come across."</i>&nbsp;&nbsp;&nbsp;- <strong>Jennifer Hartswick</strong></p>
            <p style={{font: 'Avenir', fontWeight: '250'}}><i>"One of Vermont's most respected players."</i>&nbsp;&nbsp;&nbsp;- Dan Bolles, <strong>Seven Days</strong></p>
        </div>
          <div className="col-lg-1"></div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default MusicHomePage;
