import React, { Component } from "react";
import { Button } from 'reactstrap';
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';
import { isIOS } from 'react-device-detect';

import Resume from '../../images/andrew-moroz-resume.pdf';
import ResumePage1 from '../../images/andrew-moroz-resume-p1.jpg';
import ResumePage2 from '../../images/andrew-moroz-resume-p2.jpg';

class ResumePage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.renderResume = this.renderResume.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    renderResume() {
        if (!isIOS) {
            return (

                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <div className='embed-responsive' style={{ paddingBottom: '150%', width: '80vw', margin: 'auto' }}>
                                <object data={Resume} type='application/pdf' width='100%' height='100%'></object>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                <div className="row">
                    <div className="col-md-12">
                        <Button color="primary" className="float-right"><a href={Resume} style={{ color: "#FFF", textDecoration: 'none' }}>Download PDF</a></Button>
                    </div>
                </div>

                <div className="row" style={{height: '16px'}}></div>

                <div className="row">
                    <div className="col-md-12">
                        <div style={{ width: '100%', margin: 'auto' }}>
                            <img class="img-fluid mx-auto d-block" src={ResumePage1} alt="" />
                            <div style={{ height: '16px' }}></div>
                            <img class="img-fluid mx-auto d-block" src={ResumePage2} alt="" />
                        </div>
                    </div>
                </div>
                </div>
            )
        }
    }

    render() {
        return (

            <div>
                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />
                {this.renderResume()}
                <Footer />
            </div>
        );
    }
}

export default ResumePage;
