import React, { Component } from "react";
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';
import MediaBox from './MediaBox';

// audio

// piano
import AllTheThings from '../../audio/all-the-things-you-are-mcm-trio.mp3';
import Marionettes from '../../audio/marionettes.mp3';
import Serenity from '../../audio/serenity.mp3';
import SortOfSimple from '../../audio/sort-of-simple.mp3';
import WithoutASong from '../../audio/without-a-song.mp3';

// trombone
import MtHarissa from '../../audio/mt-harissa.mp3';
import Ruchenitsa from '../../audio/ruchenitsa.mp3';
import HappyMonkey from '../../audio/happy-monkey-garuda.mp3';
import InTheWeeWeeHours from '../../audio/in-the-wee-wee-hours.mp3';


class AudioPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false,
            numPages: 0,
        }

        var pageNumber = 1;
        var prevPageNumber = 1;
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        return (

            <div>
                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />

                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}>
                                Audio
                            </p>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-1"></div>
                        <div className="col-lg-4" style={{ marginBottom: '16px' }}>

                            <p className="customText" style={{ fontSize: '25px', textAlign: 'center' }}>
                                Piano
                            </p>

                            <div className="col-lg-4" style={{ height: '2vh' }}></div>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                These are rough live recordings from a show at Sushi Yoshi in Stowe, VT on January 31, 2019.
                            </p>

                            <MediaBox source={Serenity} title="Serenity" composer="Joe Henderson" credits="Robinson Morse (bass), Geza Carr (drums), Andrew Moroz (piano)" creditsTextIsCentered={true} />

                            <MediaBox source={WithoutASong} title="Without a Song" composer="Vincent Youmans" credits="Robinson Morse (bass), Geza Carr (drums), Andrew Moroz (piano)" creditsTextIsCentered={true} />

                            <MediaBox source={AllTheThings} title="All The Things You Are" composer="Jerome Kern/Oscar Hammerstein II" credits="Robinson Morse (bass), Geza Carr (drums), Andrew Moroz (piano)" creditsTextIsCentered={true} />

                            <div className="row" style={{ height: '2vh' }}></div>
                            <hr />
                            <div className="row" style={{ height: '2vh' }}></div>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                These are selections from a live radio broadcast at WGDR in Plainfield, VT on October 27, 2014.
                            </p>

                            <MediaBox source={Marionettes} title="Marionettes" composer="Andrew Moroz" credits="Robinson Morse (bass), Geza Carr (drums), Bryan McNamara (alto sax), Andrew Moroz (piano)" creditsTextIsCentered={true} />

                            <MediaBox source={SortOfSimple} title="Sort of Simple" composer="Bryan McNamara" credits="Robinson Morse (bass), Geza Carr (drums), Bryan McNamara (alto sax), Andrew Moroz (piano)" creditsTextIsCentered={true} />


                        </div>
                        <div className="col-lg-1" style={{ marginBottom: '16px' }}></div>


                        <div className="col-lg-1"></div>
                        <div className="col-lg-4" style={{ marginBottom: '16px' }}>

                            {window.innerWidth < 1000 ? <hr style={{ height: '16px' }} /> : <div></div>}

                            <p className="customText" style={{ fontSize: '25px', textAlign: 'center' }}>
                                Trombone
                            </p>

                            <div className="col-lg-4" style={{ height: '2vh' }}></div>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is from a recording session at Egan Media in 2003 with the Black Sea Quartet.
                            </p>

                            <MediaBox source={Ruchenitsa} title="Ruchenitsa" composer="Traditional Folk Melody" credits="David Symons (accordion, vocals), Meistah Deutch (mandolin, vocals), John Thompson (bass), Jeff Campoli (percussion), Andrew Moroz (trombone)" creditsTextIsCentered={true} />

                            <div className="row" style={{ height: '2vh' }}></div>
                            <hr />
                            <div className="row" style={{ height: '2vh' }}></div>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is a live recording of the Trey Anastasio Band performing in Pittsburgh, PA on June 11, 2002.
                            </p>

                            <MediaBox source={InTheWeeWeeHours} title="In The Wee Wee Hours" composer="Professor Longhair" credits="Trey Anastasio (guitar, vocals), Russ Lawton (drums), Tony Markellis (bass), Ray Paczkowski (keyboards), Dave Grippo (alto sax), Russell Remington (tenor sax), Jennifer Hartswick (trumpet), Andrew Moroz (trombone)" creditsTextIsCentered={true} />

                            <div className="row" style={{ height: '2vh' }}></div>
                            <hr />
                            <div className="row" style={{ height: '2vh' }}></div>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                These are two selections from a live recording of James Harvey and Garuda performing at the FlynnSpace in Burlington, VT on September 14, 2002.
                            </p>

                            <MediaBox source={HappyMonkey} title="Happy Monkey" composer="James Harvey" credits="Alex Stewart (tenor sax), Andrew Moroz (trombone), Jennifer Hartswick (trumpet), James Harvey (drums), John Rivers (bass), Tom Cleary (piano)" creditsTextIsCentered={true} />

                            <MediaBox source={MtHarissa} title="Mount Harissa" composer="James Harvey" credits="Alex Stewart (tenor sax), Andrew Moroz (trombone), Jennifer Hartswick (trumpet), James Harvey (drums), John Rivers (bass), Tom Cleary (piano)" creditsTextIsCentered={true} />

                        </div>
                        <div className="col-lg-1"></div>

                    </div>

                </div>
                <Footer />
            </div>
        );
    }
}

export default AudioPage;
