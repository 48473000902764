import React, { Component } from "react";
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';


class TeachingPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {
        return (
            <div>
                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />

                <div className="row">
                    <div className="col-lg-12">
                        <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}>
                            Teaching
                            </p>
                    </div>
                </div>

                <div style={{ height: '16px' }}></div>

                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <p style={{ font: 'Avenir', fontWeight: '250' }}>
                            I have been active as a music educator since 2001, and have taught at the University of Vermont, Johnson State College, the Monteverdi Music School, and FlynnArts. In teaching, my first aim is to keep music lessons fun and engaging! I encourage students to let their own musical interests guide our work together. As a performer, I regularly play jazz, funk, rock, and pop, and I enjoy incorporating any and all of these styles into lessons. If you prefer a more traditional, reading-based approach using sheet music, I am happy to offer that format as well. I have studied improvisation, theory, and ear training extensively, and really enjoy teaching these skills.
                        </p>

                        <p style={{ font: 'Avenir', fontWeight: '250' }}>
                            I am currently accepting intermediate and advanced students. My home studio is located in Burlington, VT, but during the Covid-19 pandemic, all lessons are conducted via Zoom and/or FaceTime. Please feel free to contact me if you have questions or would like to schedule a lesson.
                        </p>
                    </div>
                    <div className="col-lg-2"></div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default TeachingPage;
