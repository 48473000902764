import React, { Component } from "react";
import { UncontrolledCarousel, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';
import MediaBox from './MediaBox';


// images
import SheetMusic from '../../images/sheet-music.jpg';

import IllStartByOpeningScorePage1 from '../../images/ill-start-by-opening-p1.jpg';
import IllStartByOpeningScorePage2 from '../../images/ill-start-by-opening-p2.jpg';
import IllStartByOpeningScorePage3 from '../../images/ill-start-by-opening-p3.jpg';
import IllStartByOpeningScorePage4 from '../../images/ill-start-by-opening-p4.jpg';

import HornSoliPage1 from '../../images/horn-soli-p1.jpg';
import HornSoliPage2 from '../../images/horn-soli-p2.jpg';

import AgnusDeiPage1 from '../../images/agnus-dei-p1.jpg';
import AgnusDeiPage2 from '../../images/agnus-dei-p2.jpg';
import AgnusDeiPage3 from '../../images/agnus-dei-p3.jpg';
import AgnusDeiPage4 from '../../images/agnus-dei-p4.jpg';
import AgnusDeiPage5 from '../../images/agnus-dei-p5.jpg';
import AgnusDeiPage6 from '../../images/agnus-dei-p6.jpg';
import AgnusDeiPage7 from '../../images/agnus-dei-p7.jpg';

import AgnusDeiOriginalPage1 from '../../images/agnus-dei-original-p1.jpg';
import AgnusDeiOriginalPage2 from '../../images/agnus-dei-original-p2.jpg';
import AgnusDeiOriginalPage3 from '../../images/agnus-dei-original-p3.jpg';
import AgnusDeiOriginalPage4 from '../../images/agnus-dei-original-p4.jpg';
import AgnusDeiOriginalPage5 from '../../images/agnus-dei-original-p5.jpg';
import AgnusDeiOriginalPage6 from '../../images/agnus-dei-original-p6.jpg';
import AgnusDeiOriginalPage7 from '../../images/agnus-dei-original-p7.jpg';

// audio

import IllStartByOpening from '../../audio/music-services-examples/ill-start-by-opening.mp3';
import AllDownTonight from '../../audio/music-services-examples/all-down-tonight-clip.mp3';
import WoodenHeart from '../../audio/music-services-examples/wooden-heart-clip.mp3';

import Banni from '../../audio/music-services-examples/banni-clip.mp3';
import Dreams from '../../audio/music-services-examples/dreams-clip.mp3';

const swaleCarouselItems = [
    {
        src: IllStartByOpeningScorePage1,
        altText: '',
        header: ''
    },
    {
        src: IllStartByOpeningScorePage2,
        altText: '',
        header: ''
    },
    {
        src: IllStartByOpeningScorePage3,
        altText: '',
        header: ''
    },
    {
        src: IllStartByOpeningScorePage4,
        altText: '',
        header: ''
    }
];

const barikaCarouselItems = [
    {
        src: HornSoliPage1,
        altText: '',
        header: ''
    },
    {
        src: HornSoliPage2,
        altText: '',
        header: ''
    }
];

const agnusDeiCarouselItems = [
    {
        src: AgnusDeiPage1,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiPage2,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiPage3,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiPage4,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiPage5,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiPage6,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiPage7,
        altText: '',
        header: ''
    }
];

const agnusDeiOriginalCarouselItems = [
    {
        src: AgnusDeiOriginalPage1,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiOriginalPage2,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiOriginalPage3,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiOriginalPage4,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiOriginalPage5,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiOriginalPage6,
        altText: '',
        header: ''
    },
    {
        src: AgnusDeiOriginalPage7,
        altText: '',
        header: ''
    }
];

// var activeIndex = 0
var animating = false

const originalSlides = agnusDeiOriginalCarouselItems.map((item) => {
    return (
        <CarouselItem
            onExiting={() => animating = true}
            onExited={() => animating = false}
            key={item.src}
        >
            <img src={item.src} alt={item.altText} />
            <CarouselCaption captionText={""} captionHeader={item.caption} />
        </CarouselItem>
    );
});

const digitizedSlides = agnusDeiCarouselItems.map((item) => {
    return (
        <CarouselItem
            onExiting={() => animating = true}
            onExited={() => animating = false}
            key={item.src}
        >
            <img src={item.src} alt={item.altText} />
            <CarouselCaption captionText={""} captionHeader={item.caption} />
        </CarouselItem>
    );
});

class MusicServicesPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);

        this.state = {
            navbarIsOpen: false,
            numPages: 0,
            activeIndex: 0
        }

        // var activeIndex = 0
        // var animating = false
    }

    // Carousel stuff

    next = () => {        
        if (animating) {            
            return;
        }
        const nextIndex = this.state.activeIndex === agnusDeiCarouselItems.length - 1 ? 0 : this.state.activeIndex + 1;        
        
        this.setState({
            activeIndex: nextIndex
        })

    }

    previous = () => {        
        if (animating) {            
            return;
        }

        const nextIndex = this.state.activeIndex === 0 ? agnusDeiCarouselItems.length - 1 : this.state.activeIndex - 1;                
        this.setState({
            activeIndex: nextIndex
        })
    }

    goToIndex = (newIndex) => {        
        if (animating) {            
            return;
        }                
        this.setState({
            activeIndex: newIndex
        })
    }



    // end carousel stuff


    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        const spacer = window.innerWidth > 992 ? '10vh' : '4vh';
        const containerWidth = window.innerWidth > 992 ? '80vw' : '100vw';

        const imageSize = window.innerWidth > 992 ? "100%" : "100%"
        const mediaBoxSpacer = window.innerWidth > 992 ? '5vh' : '2vh'

        const mediaBoxWidth = window.innerWidth > 992 ? '100%' : '60%'
        const creditsTextIsCentered = true

        const className = window.innerWidth > 992 ? "container" : ""

        const topLeftColumnWidthClassName = "col-lg-" + (window.innerWidth > 1200 ? 3 : 4)
        const topRightColumnWidthClassName = "col-lg-" + (window.innerWidth > 1200 ? 9 : 8)



        return (

            <div>

                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />

                <div className={className}>
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}>
                                Music Services
                            </p>
                        </div>
                    </div>

                    {/* <div className="container-fluid"> */}

                    <div className="row">
                        <div className={topLeftColumnWidthClassName}>
                            <img className="img-fluid mx-auto d-block rounded" style={{ width: '100%', maxWidth: '500px' }} src={SheetMusic} alt="" />
                        </div>
                        <div className={topRightColumnWidthClassName}>
                            <p style={{ font: 'Avenir', fontWeight: '250', paddingTop: '16px', textAlign: 'left' }}>
                                I offer custom transcription, arranging, composition, and copying services. I have over 18 years of experience writing and arranging for a wide variety of ensembles, from jazz, funk, rock, and salsa bands to pit orchestras and classical groups. My writing has been performed and recorded by many Vermont artists including <a href="http://www.rayvegamusic.com" target="_blank" rel="noopener noreferrer">Ray Vega</a>, <a href="http://www.jenniferhartswick.com" target="_blank" rel="noopener noreferrer">Jennifer Hartswick</a>, <a href="http://www.barikamusic.com" target="_blank" rel="noopener noreferrer">Barika</a>, and <a href="http://www.swalesong.com" target="_blank" rel="noopener noreferrer">Swale</a>. As a copyeditor, I have worked extensively on several theatrical productions, including a 2016 New York Theatre Workshop production of Anais Mitchell's <a href="http://www.hadestown.com" target="_blank" rel="noopener noreferrer">Hadestown</a> (now on Broadway).
                            </p>
                            <p style={{ font: 'Avenir', fontWeight: '250', textAlign: 'left' }}>
                                Please see below for details and examples, and feel free to <a href="/contact">contact me</a> for more information or to get a quote for your project.
                            </p>

                        </div>
                    </div>
                    {/* </div>               */}
                </div>





                <div className="container" style={{ maxWidth: containerWidth }}>

                    <div className="row" style={{ height: spacer }}></div>

                    <div className="row">
                        <div className="col-lg-3" style={{ paddingBottom: '16px' }}>
                            <p className="customText" style={{ fontSize: '18px', fontWeight: '450', textAlign: 'center' }}>
                                Transcription
                            </p>
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                I can transcribe and notate music in a wide range of styles and formats, from classical orchestral works to modern jazz to heavy metal electric guitar solos. I strive for uncompromising accuracy, and aim to provide the fastest turnaround time possible – most projects can be completed within one week.
                            </p>
                        </div>

                        <div className="col-lg-3" style={{ paddingBottom: '16px' }}>
                            <p className="customText" style={{ fontSize: '18px', fontWeight: '450', textAlign: 'center' }}>
                                Arranging
                            </p>
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                I am fluent in orchestration, and can arrange written or recorded music to be performed by any instrument or ensemble you require. This is often requested in conjunction with transcription.
                            </p>
                        </div>
                        <div className="col-lg-3" style={{ paddingBottom: '16px' }}>
                            <p className="customText" style={{ fontSize: '18px', fontWeight: '450', textAlign: 'center' }}>
                                Composition
                            </p>
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                I enjoy composing for all settings and occasions in a diverse assortment of genres. Whether you need music written ‘from the ground up’, or just require an additional layer for a piece such as a string or horn part, I am happy to help.
                            </p>
                        </div>
                        <div className="col-lg-3" style={{ paddingBottom: '16px' }}>
                            <p className="customText" style={{ fontSize: '18px', fontWeight: '450', textAlign: 'center' }}>
                                Copying
                            </p>
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                I create professional, digital scores from printed and handwritten sheet music.
                            </p>
                        </div>
                    </div>

                    <div className="row" style={{ height: /*spacer*/'4vh' }}></div>

                    <div className="row">

                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}>
                                Sample Scores and Audio
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'center' }}>
                                <strong>Excerpts from <i>There's No One Here</i></strong>
                            </p>
                        </div>

                        <div className="row" style={{ height: '6vh' }}></div>

                        <div className="col-lg-1"></div>
                        <div className="col-lg-4" style={{ marginBottom: '16px' }}>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                These are clips of some horn writing I did for Swale for their 2017 release <i>There's No One Here.</i> <i>I'll Start By Opening</i> is a short chorale I wrote for eight saxophones and trombones. For <i>All Down Tonight</i>, the horn parts were composed by Eric Olsen of Swale; I transcribed and arranged them for saxophones and trombones. On <i>Wooden Heart</i>, I wrote horn pads to back up the vocals and lead into the alto sax solo by Annakalmia Traver.
                            </p>

                            <div className="row" style={{ height: mediaBoxSpacer }}></div>

                            <MediaBox source={IllStartByOpening} title="I'll Start By Opening" credits="Bryan McNamara (alto, tenor, and bari saxes) and Andrew Moroz (trombones)" creditsTextIsCentered={creditsTextIsCentered} width={mediaBoxWidth} />

                            <div className="row" style={{ height: mediaBoxSpacer }}></div>

                            <MediaBox source={AllDownTonight} title="All Down Tonight" credits="Featuring Swale (Eric Olsen, Amanda Gustafson, Jeremy Frederick, and Tyler Bolles) plus Bryan McNamara (alto, tenor, and bari saxes) and Andrew Moroz (trombones)" creditsTextIsCentered={creditsTextIsCentered} width={mediaBoxWidth} />

                            <div className="row" style={{ height: mediaBoxSpacer }}></div>

                            <MediaBox source={WoodenHeart} title="Wooden Heart" credits="Featuring Swale (Eric Olsen, Amanda Gustafson, Jeremy Frederick, and Tyler Bolles) with special guest Annakalmia Traver (vocals & alto sax), plus Bryan McNamara (alto, tenor, and bari saxes) and Andrew Moroz (trombones)" creditsTextIsCentered={creditsTextIsCentered} width={mediaBoxWidth} />

                        </div>

                        <div className="col-lg-1"></div>

                        <div className="col-lg-6">

                            <UncontrolledCarousel items={swaleCarouselItems} slide={false} autoPlay={false} interval={false} />

                        </div>
                    </div>

                    <div className="row" style={{ height: '8vh' }}></div>


                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'center' }}>
                                <strong>Excerpts from <i>When The Time Comes</i></strong>
                            </p>
                        </div>

                        <div className="row" style={{ height: '6vh' }}></div>

                        <div className="col-lg-1"></div>
                        <div className="col-lg-4" style={{ marginBottom: '16px' }}>

                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                These are two clips of some writing I did for <a href="http://www.barikamusic.com" target="_blank" rel="noopener noreferrer">Barika's</a> 2017 release <i>When The Time Comes</i>. <i>Banni</i> has a soli I composed for five horns: Luke Laplant (bari sax), Jake Whitesell (alto/tenor sax), Dave Purcell (trumpet), Will Andrews (trumpet), and myself (trombone). I co-wrote <i>Dreams</i> with Craig Myers and Barika. I composed the chord progression; Craig and the band added all the rest of the elements, with lyrics and vocals contributed by Stephanie Heaghney.
                            </p>

                            <div className="row" style={{ height: '2vh' }}></div>

                            <MediaBox source={Banni} title='Banni' credits='Traditional; music and arrangement by Craig Myers and Barika' creditsTextIsCentered={creditsTextIsCentered} width={mediaBoxWidth} />

                            <div className="row" style={{ height: '5vh' }}></div>

                            <MediaBox source={Dreams} title='Dreams (Featuring Stephanie Heaghney)' credits='Craig Myers/Andrew Moroz/Barika' creditsTextIsCentered={creditsTextIsCentered} width={mediaBoxWidth} />

                        </div>

                        <div className="col-lg-1"></div>

                        <div className="col-lg-6">

                            <UncontrolledCarousel items={barikaCarouselItems} slide={false} autoPlay={false} interval={false} />

                        </div>
                    </div>

                    <div className="row" style={{ height: '8vh' }}></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'center' }}>
                                <strong>Digitization of <i>Agnus Dei</i></strong>
                            </p>
                        </div>
                    </div>

                    {/* <div className="row" style={{ height: '6vh' }}></div> */}

                    <div className="row">
                        {/* <div className="col-lg-1"></div> */}

                        <div className="col-lg-12" style={{ marginBottom: '16px' }}>
                            <p className="customText" style={{ fontSize: '16px', textAlign: 'left' }}>
                                This is a digitization of an arrangement for piano and voice of Mozart's <i>Agnus Dei</i>, which was requested by a client so the arrangement could be transposed. Digitizing printed sheet music offers a number of advantages, making it much easier to edit and share. When doing this kind of work, I strive not only to preserve all markings in the original sheet music, but to closely match the overall appearance when appropriate.
                            </p>
                        </div>

                        {/* <div className="col-lg-1"></div> */}

                        {/* <div className="col-lg-6">
                            <UncontrolledCarousel items={agnusDeiCarouselItems} slide={false} autoPlay={false} interval={false} />
                        </div> */}


                        {/* TO DO: update layout here to accommodate two carousels – e.g. one 12 column for section title and text, and then two 6s for each carousel  */}

                    </div>

                    <div className="row">
                        <div className="col-lg-6" style={{marginBottom: '16px'}}>
                        <p className="customText" style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
                            Original
                        </p>
                            <Carousel
                                activeIndex={this.state.activeIndex}
                                next={this.next}
                                previous={this.previous}

                                slide={false}
                                autoPlay={false}
                                interval={false}
                            >
                                <CarouselIndicators items={agnusDeiOriginalCarouselItems} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                                {originalSlides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>                        

                        <div className="col-lg-6">
                        <p className="customText" style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
                            Digitized
                        </p>
                            <Carousel
                                activeIndex={this.state.activeIndex}
                                next={this.next}
                                previous={this.previous}

                                slide={false}
                                autoPlay={false}
                                interval={false}
                            >
                                <CarouselIndicators items={agnusDeiCarouselItems} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                                {digitizedSlides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>

                    </div>

                </div>
                <Footer />
            </div>
        );
    }
}

export default MusicServicesPage;
