import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Provider } from "react-redux";
import returnStoreAndPersistor from "./store";

import { PersistGate } from 'redux-persist/integration/react'

import MusicHomePage from "./components/home/HomePage";
import AboutPage from './components/pages/AboutPage';
import ResumePage from './components/pages/ResumePage';
import MusicServicesPage from './components/pages/MusicServicesPage';
import ContactPage from './components/pages/ContactPage';
import AudioPage from './components/pages/AudioPage';
import TeachingPage from './components/pages/TeachingPage';
import VideoPage from './components/pages/VideoPage';
import PhotosPage from './components/pages/PhotosPage';

import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import './Spinner.scss';


const { store } = returnStoreAndPersistor()
const { persistor } = returnStoreAndPersistor()

class App extends Component {

  componentWillMount() {
    // console.log('App.componentWillMount called.');

    global.serverAddress = (process.env.NODE_ENV === 'production') ? 'https://stark-crag-11008.herokuapp.com' : 'http://localhost:5000'
  }

  render() {
    console.log('running App.render()');

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <div className="App">
              <div className="container-fluid">                
                <Route exact path="/" component={MusicHomePage} />
                <Route exact path="/about/about-andrew" component={AboutPage} />
                <Route exact path="/about/resume" component={ResumePage} />
                <Route exact path="/music-services" component={MusicServicesPage} />
                <Route exact path="/audio" component={AudioPage} />
                <Route exact path="/contact" component={ContactPage} />
                <Route exact path="/teaching" component={TeachingPage} />
                <Route exact path="/video" component={VideoPage} />
                <Route exact path="/photos" component={PhotosPage} />
              </div>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
