import React from 'react';

const MediaBox = (props) => {

    const source = props.source
    const creditsTextIsCentered = props.creditsTextIsCentered
    const title = props.title
    const credits = props.credits
    const composer = props.composer
    const type = props.type
    const borderStyle = window.innerWidth > 768 ? 'hidden' : 'solid'

    const marginBottom = window.innerWidth > 1000 ? '0px' : '8px';

    function box() {
        if (type == "video") {
            return (
                <video class="customPlayer" title={title} controls style={{ width: '100%', maxWidth: '550px' }}>
                    <source src={source} />
                    Your browser does not support the <code>video</code> element.
                </video>
            )
        } else {
            return (
                <audio class="customPlayer" title={title} controls style={{ width: '100%', maxWidth: '550px' }}>
                    <source src={source} />
                Your browser does not support the <code>audio</code> element.
                </audio>
            )
        }
    }

    return (
        <div class="container-fluid mediaBox" style={{ marginBottom: marginBottom, maxWidth: '550px', borderStyle: borderStyle }}>
            <div style={{ marginTop: '8px' }}>
                <p class="customText" style={{ textAlign: 'center', fontWeight: '450', marginBottom: '0px' }}>{title}</p>
                <p class="customText" style={{ textAlign: 'center', fontWeight: '350', fontSize: '11px', marginTop: '0px' }}>{composer}</p>
            </div>
            <div align="center">                
                {box()}
            </div>
            <div>
                {creditsTextIsCentered ?
                    <p class="customText" style={{ fontSize: '10px', textAlign: 'center' }}>{credits}</p>
                    :
                    <p class="customText" style={{ fontSize: '10px' }}>{credits}</p>
                }
            </div>
        </div>
    )
}

export default MediaBox;
