import React, { Component } from "react";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let photoCredit = this.props.photoCredit;

        let backgroundColor = this.props.backgroundColor;
        if (backgroundColor === undefined) { backgroundColor = 'rgba(0, 0, 0, 0)' }

        return (
            <div className="d-flex flex-column" style={{ backgroundColor: backgroundColor }}>

                <div className="wrapper flex-grow-1"></div>
                <footer>
                    <div className="container-fluid text-center py-4 text-black-50">
                        <small className="footerText">
                            {photoCredit !== undefined ? <div>Photo credit: {photoCredit}</div> : <div></div>}
                        Copyright &copy; 2020 Andrew Moroz
                        </small>
                    </div>
                </footer>

            </div>

        );
    }
}

export default Footer;
