import React, { Component } from "react";
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';

import AndrewPiano from '../../images/andrew-piano-1.jpg';

class AboutPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {
        return (

            <div>
                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />

                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <img class="img-fluid mx-auto d-block rounded" src={AndrewPiano} alt="" />
                    </div>
                    <div className="col-lg-3"></div>
                </div>


                <div class="row" style={{ height: '5vh' }}></div>

                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <p style={{ font: 'Avenir', fontWeight: '250', textAlign: 'left' }}>
                            Growing up near Burlington, VT, Andrew Moroz had regular lessons with multi-instrumentalist and composer James Harvey. He attended the Hartt School of Music and later New School University, studying with Steve Davis, Jackie McLean, Gary Valente, Jane Ira Bloom, Andrew Cyrille, and Charles Tolliver. While at the Hartt School, Andrew joined the Trey Anastasio Band, with which he toured and recorded for the next four years, sharing the stage with artists like Carlos Santana, Dave Matthews, Peter Apfelbaum, John Medeski, Les Claypool, and Warren Haynes. Since returning to Vermont, Andrew has performed and recorded on trombone and piano with numerous local groups, and served as adjunct faculty at the University of Vermont and Johnson State College. From 2010-2012 he toured with Anais Mitchell’s Hadestown Orchestra, which brought collaborations with Todd Sickafoose, Ani DiFranco, and Corin Tucker. Andrew was also a regular member of the Discover Jazz Big Band, working with greats like Jim Hall, Randy Brecker, Paquito D’Rivera, and Jim McNeely.
                        </p>
                    </div>
                    <div className="col-lg-2"></div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default AboutPage;
