import React, { Component } from "react";
import Footer from '../layout/Footer';
import NavigationBar from '../layout/NavigationBar';
import Helmet from 'react-helmet';

import PhotoGallery from './PhotoGallery';

class PhotosPage extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            navbarIsOpen: false
        }
    }

    toggle() {
        this.setState({
            navbarIsOpen: !this.state.navbarIsOpen
        })
    }

    render() {

        return (

            <div>
                <Helmet bodyAttributes={{ style: 'background-color: #bbd0c9' }} />
                <NavigationBar />

                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <p className="customText" style={{ fontSize: '35px', textAlign: 'center' }}>
                                Photos
                            </p>
                        </div>
                    </div>

                    <PhotoGallery />

                </div>

                <Footer />
            </div>
        );
    }
}

export default PhotosPage;
