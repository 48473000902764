import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, DropdownItem } from 'reactstrap';

import '../../App.css';


class NavigationBar extends Component {

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleError = this.toggleError.bind(this);

        this.state = {
            isOpen: false,
            aboutDropdownIsOpen: false,
            dropdownError: false,
        };
    }


    toggleNavbar() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    toggleError() {
        this.setState({
            dropdownError: !this.state.dropdownError
        });
    }


    render() {

        return (

            <section className="sidebar">
                <Navbar light expand="lg">
                    <div>
                        <NavbarBrand tag={Link} to="/">
                            <div>
                                <h1 className="navbarHeaderText" >Andrew Moroz</h1>

                                <hr />

                                <div width="inherit" style={{ margin: 'auto' }}>
                                    <h3 className="navbarSubtitleText" style={{ textAlign: 'center' }}>PIANIST&nbsp;•&nbsp;TROMBONIST&nbsp;•&nbsp;COMPOSER</h3>
                                </div>
                            </div>
                        </NavbarBrand>
                    </div>

                    <NavbarToggler onClick={this.toggleNavbar} />

                    <Collapse isOpen={this.state.isOpen} navbar className="w-100">

                        <Nav className="ml-auto py-0" vertical navbar style={{ textAlign: 'right' }}>

                            <NavItem className="navLinkText float-right py-0" /*style={{ marginTop: '-8px', marginBottom: '-8px' }}*/>
                                <NavLink className="py-0" tag={Link} to="/about/about-andrew">About Andrew</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/music-services">Music Services</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/teaching">Teaching</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/about/resume">Resume</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/photos">Photos</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/audio">Audio</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/video">Video</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" tag={Link} to="/contact">Contact</NavLink>
                            </NavItem>

                            <NavItem className="navLinkText float-right py-0">
                                <hr />
                            </NavItem>
                            
                            <NavItem className="navLinkText float-right py-0">
                                <NavLink className="py-0" href="https://www.morozdigital.com">Moroz Digital</NavLink>
                            </NavItem>

                        </Nav>

                    </Collapse>
                </Navbar>

            </section>

        );

    }
}

// export default connect(
//   mapStateToProps,
//   { logoutUser }
// )(NavigationBar);

export default NavigationBar;
